@import "./variables";

header{
    background: white;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    align-items: center;
    padding: 0.5rem 2rem;
    color:$color-info-dark;
    .clock{
        margin: auto;
        width: 15%;
    }
    span{
        display: flex;
        gap:0.5rem;
        cursor: pointer;
        align-items: center;

    }
    button{
        background: transparent;
        border:none;
        cursor: pointer;
        
    }
    .mobileNav{
        display: none;
    }
}
@media (max-width: $breakpoint-tablet) {
    header{
        flex-direction: row-reverse;
        .mobileNav{
            display: block;

            position: absolute;
            width: 70%;
            height: 12rem;
            background: $color-primary;
            border-radius: $border-radius-3;
            padding: 0.5rem;
            z-index: 13;
            left: 50%;
            top:50%;
            transform: translate(-50%,25%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            gap: 1rem;
            .nav__link{
                color: white;
                width: 80%;
                padding: 0.4rem;
                
                
            }

        }
    }
 
   
}

@import "./variables";

.admin__view{
   // background: rgb(149, 55, 55);
    height: 100%;
    width: 100%;
    display: grid;
    //place-items: center;
    grid-template-columns: 1.5fr 1fr;
    overflow: hidden;
    .right{
       // background: grey;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        overflow: hidden;
        .card{
           
            background: white;
            border-radius: $border-radius-3;
            width: 80%;
            margin: 0.5rem auto;
            height: 18rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            gap: 0.3rem;
            img{
                background-color: $color-info-dark;
                border-radius: 50%;
                width: 5rem;
                height: 5rem;
            }
            h3{
                font-size: 2rem;
                color: $color-dark;
                
            }
            .card-info{
                
                border: 1px solid $color-info-dark;
                border-radius: $border-radius-1;
                width: 80%;
                display: flex;
                align-items: center;
                gap: 0.2rem;
                :first-child{
                    color: $color-dark;
                    background: $color-light;
                    padding: 0.1rem 0.2rem;
                    height: 100%;
                    border-right: 1px solid $color-info-dark;

                }
            }
         
        }
        .right-manage{
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
            .btn{
                padding: 0.5rem 1rem;
                border-radius: $border-radius-3;
                font-weight: 700;
            }
            .btn-new{
                background: transparent;
                border: 2px solid $color-primary;
                color: $color-info-dark;
               
            }
            .btn-del{
                background: $color-danger;
                color: white;
                border: none;
                box-sizing: content-box;
                &:hover{
                    background: transparent;
                    border: 2px solid $color-danger;
                    color: $color-info-dark;

                }
            }
            .active{
                border-radius: $border-radius-3;
                
                background: $color-primary;
                color: $color-info-light;

            }


        }
        .forms-container{
            background: white;
            border-radius: $border-radius-3;
            flex-grow: 1;
            display: flex;
            width: 80%;
            justify-content: center;
            align-items: center;
            .addnew{
                display: flex;
                flex-direction: column;
                gap:0.2rem;
                padding: 0.4rem;
                label{
                    color: $color-dark;
                    font-weight: 500;
                }
              
                .inputs{
                    width: 100%;
                    padding: 0.4rem;
                    background: transparent;
                    border: 1px solid $color-info-dark;
                    border-radius: $border-radius-1;
                    outline: none;
                    color: $color-dark;
                }
                .checkbox{
                    accent-color: $color-primary;
                    display: flex;
                    align-items: center;
                    padding: 0.4rem;
                    gap: 0.5rem;
                }
                .btn-submit{
                    background: $color-primary;
                    border: none;
                    padding: 0.5rem;
                    font-weight: 600;
                    letter-spacing: 0.2rem;
                    border-radius: $border-radius-3;
                    color: white;
                }
            }
        }

    }
    .left{
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 100%;
        padding:1rem;
        overflow: hidden;
        .starts{
            width: 100%;
            justify-content: space-between;
            display: flex;
            padding: 0.8rem;
            gap: 0.5rem;
        .starts__card{
            width: 12rem;
            height: 7rem;
            background: white;
            padding: 0.6rem;
            display: flex;
            gap: 0.5rem;
            flex-direction: column;
            border-radius: $border-radius-3;
            :first-child{
                color: $color-primary;
                font-size: 1.7rem;
            }
            h3{
                color: $color-dark;
            }
            small{
                color: $color-info-dark;
            }
        }}

        form{
            position: relative;
            padding-top: 1.5rem;
            //background: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        .search{
            
            position: relative;
            width: 100%;
            margin: 0 auto;
            background: transparent;
            border-radius: $border-radius-2;
            border: 2px solid $color-info-dark;
            padding: 0.5rem;
        }
        .search__icon{
            position: absolute;
            font-size: 2rem;
            margin-right: 0.5rem;
            color: $color-info-dark;
        }
    
    }
    .welcome-info{
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0;
        color: $color-info-dark;
        h2{
            color:$color-dark;
        }


    }
    .users-list{
        display: flex;
        flex-direction: column;
        overflow:hidden;    
        .user-title{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            h3{
                color:$color-dark
            }
            select{
                outline: none;
                border-radius: $border-radius-1;
                background: transparent;
                color: $color-info-dark;
            }
        }
        .results{
            background: white;
            border-radius: $border-radius-3;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: auto;
            max-height: 35rem;
            width: 100%;
            span{
                padding: 0.3rem;
                font-weight: 500;
                width: 98%;
                margin: 0.2rem auto;
                color: $color-info-dark;
                border-radius: $border-radius-1;
                &:hover{
                    border:2px solid $color-primary;
                    
                }
                small{
                    opacity: 0.9;
                }
            }
           
        }

    }
    }
}
@media (max-width: $breakpoint-tablet) {
    .admin__view{
        height: auto;
        grid-template-columns: 1fr;
        overflow: unset;

    }
    .left{
        width: 100%;
    form{
        width: 95%;
        margin: 0 auto;
    }
    .users-list{
        padding: 1rem 0;
        width: 90%;
        margin: 0 auto;
   
    }
    }
    .right{
        width: 100%;
        height: auto;
        .card{
            width: 90% !important;
        }
        .btn-del{
            &:focus{
                background: transparent;
                border: 2px solid $color-danger;
                color: $color-info-dark;

            }
        }
        .forms-container{
            width: 90% !important;
            padding: 1rem;
            .addnew{
                width: 80%;
            }
        }
    }
   
   
}
@media (max-width: $breakpoint-mobile) {
    .admin__view{
        .right-manage{
            width: 90%;
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 0.5rem;
            .btn{
                padding: 0.2rem 0.5rem !important;
                border-radius: $border-radius-3;
                font-weight: 700;
            }
            .btn-del{
                &:focus{
                    background: transparent;
                    border: 2px solid $color-danger;
                    color: $color-info-dark;

                }
            }
        }
    
 
}
   
}
@import "./variables";

footer{
    background: white;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    color:$color-info-dark;
    font-weight: 500;
    a{
        color: $color-primary;
        font-weight: 600;
    }

    
}
@media (max-width: $breakpoint-tablet) {
    footer{
        padding: 0.5rem 2rem;
        font-weight: 300;
        font-size: 0.7rem;
        a{
         
            font-weight: 500;
        }
    
        
    }
}
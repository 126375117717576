// color pallete variables

$color-primary:#d31245;
$color-danger:#ff7782;
$color-success: #41f1b6;
$color-warning: #ffbb55;
$color-info-dark: #2e323d;
$color-info-light:#dce1eb;
$color-dark: #363949;
$color-black: black;
$color-light: rgba(132,139,200,0.18);
$color-primary-variant:#111e88;
$color-dark-variant:#677483;
$color-background:#f6f6f9;
$color-timecard-background: #e5e6e8;

//border radius
$card-border-radius:2rem;
$border-radius-1:0.4rem;
$border-radius-2:0.8rem;
$border-radius-3:1.2rem;

//padding
$card-padding:1.8rem;
$padding-1:1.2rem;

//box shadow
$box-shadow:0.2rem 3rem $color-light;


//breaking point
$breakpoint-tablet: 850px;
$breakpoint-mobile: 700px;

$assetPath: "/"